.hotSpotWrapper {
  position: relative;
}
.hotSpotClose {
  display: none;
}
.contentWrapper {
  position: relative;
}

.modalInput {
  position: absolute;
}

.hotSpotWrapper .hotSpotLabel {
  background: #ff7a00;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  position: relative;
  transition: all 300ms ease;
  width: 20px;
  z-index: 2;
  color: #fff;
  font-weight: bold;
  text-indent: 0.6rem;
  font-size: 1.5rem;
  padding-top: 0;
  line-height: 2rem;
  margin: 0 auto;
  margin-bottom: 1.2rem;
}

.hotSpotLabel.legend {
  background: #ff7a00;
  border-radius: 50%;
  height: 20px;
  transition: all 300ms ease;
  width: 20px;
  z-index: 2;
  line-height: 2rem;
  min-width: 2rem;
  text-align: center;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
@media (min-width: 1280px) {
  .hotSpotWrapper .hotSpotLabel:before,
  .hotSpotWrapper .hotSpotLabel:after {
    background: #fff;
    content: '';
    cursor: pointer;
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .legend.hotSpotLabel:before,
  .legend.hotSpotLabel:after {
    background: #fff;
    content: '';
    display: block;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 1279px) {
  .hotSpotLabel.legend {
    color: #fff;
    font-weight: bold;
    font-size: 1.5rem;
    padding-top: -0.2rem;
  }
  .hotSpotWrapper .hotSpotLabel:hover {
    transform: none !important;
  }

  .hotSpotWrapper .hotSpotLabel {
    cursor: default !important;
  }
}
.legend.hotSpotLabel:before {
  height: 2px;
  width: 10px;
  left: unset;
  transform: translate(5px, -1px);
}
.legend.hotSpotLabel:after {
  height: 10px;
  width: 2px;
  transform: translate(9px, -5px);
  left: unset;
}
.hotSpotWrapper .hotSpotLabel:before {
  height: 2px;
  width: 50%;
}
.hotSpotWrapper .hotSpotLabel:after {
  height: 50%;
  width: 2px;
}
.hotSpotWrapper .hotSpotInput ~ .hotspot__Overlay {
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 3;
}
.hotSpotWrapper .hotSpotLabel:hover:not(.legend) {
  transform: rotate(45deg);
}

.hotSpotWrapper.hotSpotBlur figure {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}
.hotSpotWrapper.hotSpotBlur .hotSpotInput {
  display: none;
}
.hotSpotWrapper.hotSpotBlur .hotSpotLabel {
  opacity: 0.5;
  pointer-events: none;
}
.hotSpotWrapper.hotSpotBlur .hotSpotInput:checked {
  display: inline-block;
}
.hotSpotWrapper.hotSpotBlur .hotSpotInput:checked + .hotSpotLabel {
  opacity: 1;
  pointer-events: auto;
}

.hotSpotModal {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1;
}

.circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 122, 0, 0.4);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 122, 0, 0);
  }
}

@media (max-width: 768px) {
  .hotSpotWrapper .hotSpotClose {
    background: #ff7a00;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    height: 20px;
    right: -5px;
    position: absolute;
    top: -5px;
    transition: all 300ms ease;
    transform: rotate(45deg);
    width: 20px;
    z-index: 5;
  }
  .hotSpotWrapper .hotSpotClose:before,
  .hotSpotWrapper .hotSpotClose:after {
    background: #fff;
    content: '';
    cursor: pointer;
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .hotSpotWrapper .hotSpotClose:before {
    height: 2px;
    width: 40%;
  }
  .hotSpotWrapper .hotSpotClose:after {
    height: 40%;
    width: 2px;
  }
}
